import React from "react"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <div>
    <Helmet title="404: Not found" />

    <h1>NOT FOUND :(</h1>
  </div>
)

export default NotFoundPage
